// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "n_qM d_bD";
export var storyRowWrapper = "n_hx d_hx d_bK";
export var storyLeftWrapper = "n_qN d_bz d_bP";
export var storyWrapperFull = "n_qP d_cD";
export var storyWrapperFullLeft = "n_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "n_mv d_hy";
export var storyLeftWrapperCenter = "n_qQ d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "n_qR d_hF";
export var storyHeader = "n_qS d_hD d_w d_cs";
export var storyHeaderCenter = "n_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "n_hB d_hB d_by d_dw";
export var storyBtnWrapper = "n_qT d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "n_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "n_qV d_fg d_Z";
export var imageWrapperFull = "n_qW d_w d_H d_bf d_Z";
export var SubtitleSmall = "n_qd q_qd q_qY q_q8";
export var SubtitleNormal = "n_qf q_qf q_qY q_q9";
export var SubtitleLarge = "n_qg q_qg q_qY q_rb";
export var textLeft = "n_dv";
export var textCenter = "n_dw";
export var textRight = "n_dx";